<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        Which subshells can have electrons with the given magnetic quantum numbers,
        <stemble-latex content="$m_\ell ?$" />
        Check all that apply.
      </p>

      <template v-for="(ml, i) in mlValues">
        <p :key="`pt-${i + 1}-text`" class="mb-1">
          <stemble-latex :content="`$m_\\ell = ${ml}$`" />
        </p>

        <v-row :key="`pt-${i + 1}-row`" class="pl-14 mb-2">
          <v-checkbox
            v-for="option in subshellOptions"
            :key="`pt-${i + 1}-` + option.value"
            v-model="inputs[`input${i + 1}`]"
            :value="option.value"
            class="pr-12"
          >
            <template v-slot:label>
              <stemble-latex :content="`$\\text{${option.text}}$`" />
            </template>
          </v-checkbox>
        </v-row>
      </template>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question21',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: [],
        input2: [],
        input3: [],
        input4: [],
      },
      mlValues: [-1, 0, 2, -3],
      subshellOptions: [
        {text: 's', value: 's'},
        {text: 'p', value: 'p'},
        {text: 'd', value: 'd'},
        {text: 'f', value: 'f'},
      ],
    };
  },
};
</script>
